<template>
  <v-app ref="sectionTop" :class="{ 'DarkMode' : $store.state.component.mode == 'dark' }">
    <app-bar />
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
    <app-footer />
    <v-btn class="top-scroll-btn" v-if="scrollDown" @click="toSectionTop('sectionTop')"></v-btn>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import define from '@/define';

import AppBar from "@/components/common/AppBar.vue"
import AppFooter from '@/components/common/AppFooter.vue';
import bus from '@/utils/eventBus';
export default {
  name: 'App',
  data() {
    return {
      layout: 'full-layout',
      scrollCount: 0,
      scrollDown: false,
    }
  },
  components: {
    AppBar,
    AppFooter
  },
  computed: {
    ...mapGetters([
      "maintoken",
      "updateCounter",
    ]),
  },
  watch: {
    $route(to) {
      this.layout = to.meta.layout || 'full-layout';
    },
    updateCounter(i) {
      if (i % 6 == 0) {
        this.$store.dispatch("currentBlockHeight", {});
      }
    },
  },
  mounted() {
    this.$store.dispatch("currentBlockHeight", {});
    this.$store.dispatch("startBatch");

    this.$store.dispatch('checkMode');
    this.$store.dispatch("setupContractList");
    this.$store.dispatch("formulators", define.CONTRACT.formulator);
    this.getMaintokenInfo();

    this.layout = this.$route.meta.layout || 'full-layout';

    bus.$on('mode', (payload) => {
      const mode = payload;
      this.$store.dispatch('setMode', { mode });
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    getMaintokenInfo() {
      this.rpccall("view.maintoken").then((list) => {
        let contAddr = list.toLowerCase()
        this.$store.dispatch("maintoken", contAddr);
        this.rpccall("view.call", [contAddr, "symbol", []])
          .then((list) => {
            this.$store.dispatch("symbol", {
              addr: contAddr,
              symbol: list[0],
            });
          })
          .catch((err) => {
            console.log(err);
          });

      });
    },
    toSectionTop(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop - 100;
      window.scrollTo(0, top);
    },
    handleScroll() {
      const mqr = window.matchMedia("screen and (max-width: 1024px)");
      this.scrollCount = window.scrollY;
      if (this.scrollCount > 400) {
        this.scrollDown = true;
      } else if (this.scrollCount > 300 && mqr.matches) {
        this.scrollDown = true;
      } else  {
        this.scrollDown = false;
      }
    },
    removeHandleScroll() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
};
</script>
